<template>
  <div>
    <div class="con-list i-c-teacher f-clearfix">
      <!-- <div class="i-text-uesr-bg i-text-remark-top"></div> -->
      <!-- <div class="i-uesr-bg i-uesr-bg1"></div> -->
      <div class="i-uesr-bg i-uesr-bg2"></div>
      <div class="i-uesr-bg i-text-remark-bottom"></div>
      <div class="l-teacher" v-loading="loading">
        <el-carousel :interval="5000" arrow="never" indicator-position="outside">
          <el-carousel-item v-for="item in cardList" :key="item.id">
            <!-- <a :href="item.link" target="_blank"> -->
            <div class="i-teacher">
              <div class="i-images">
                <!-- <div class="i-uesr-bg i-uesr-bg1"></div>
                <div class="i-uesr-bg i-uesr-bg2"></div> -->
                <!-- <div class="i-uesr-bg i-uesr-bg3"></div> -->
                <div class="i-img" @click="toDetail(item)">
                  <img v-img.lazy="item.headImgUrl" :alt="item.name" />
                </div>
              </div>
              <div class="i-text">
                <div class="i-text-name" @click="toDetail(item)">
                  <h2 class="live-flag">{{item.name}}</h2>
                </div>
                <div class="i-text-title">
                  <p class="live-flag">{{item.title}}</p>
                </div>
                <div class="i-text-remark">
                  <div class="i-text-remark-html" v-html="item.introduce"></div>
                </div>
                <div class="i-text-course">
                  <h3>主讲课程</h3>
                  <ul
                    v-if="item.courseList&&item.courseList.length>0"
                    class="f-clearfix m-course-list"
                  >
                    <li v-for="(child,i) in item.courseList" :key="i" v-if="i<3">
                      <a :href="detailUrl+child.id" target="_blank">{{child.name}}</a>
                    </li>
                  </ul>
                  <div v-else style="color: #a9adb5">暂无课程</div>
                </div>
                <!-- <div class="i-text-uesr-bg i-text-remark-top"></div> -->
                <!-- <div class="i-uesr-bg i-text-remark-bottom"></div> -->
              </div>
            </div>
            <!-- </a> -->
          </el-carousel-item>
        </el-carousel>
      </div>
      <ul class="index-teacher f-clearfix" v-if="tableList.length>0">
        <li
          class="item-teacher"
          v-for="(item,index) in tableList"
          :key="index"
          :title="`${item.name}`"
          @click="toDetail(item)"
        >
          <div class="item-img">
            <img v-img.lazy="item.headImgUrl" :alt="item.realName" />
          </div>
          <h3>{{item.name}}</h3>
          <div class="item-org">
            <p>{{item.title}}</p>
          </div>
        </li>
        <li @click="toTeacherList" class="item-to-menu">
          <div style="text-align: center;">查看更多讲师</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableList: [],
      cardList: [],
      detailUrl: "/course/courseDetail?id=",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      loginUrl: this.$setting.loginUrl,
      loading: false,
    };
  },
  mounted() {
    // this.getList();
    this.getCard();
  },
  methods: {
    toDetail(val) {
      window.open("/teacher/teacherDetail?id=" + val.id);
    },
    getCard() {
      this.loading = true;
      this.$http
        .get("/layout/home/teacher")
        .then((res) => {
          this.loading = false;
          this.cardList =
            res.result.highRecTeachers != null
              ? res.result.highRecTeachers
              : [];
          this.tableList =
            res.result.recTeachers != null ? res.result.recTeachers : [];
        })
        .catch((msg) => {
          console.log(msg);
          this.loading = false;
        });
    },
    toTeacherList() {
      window.open(`/teacher`);
    },
    getList() {
      let par = {
        pageSize: 6,
        currentPage: 1,
      };
      this.$http
        .post("api/web/teacher/rank", par)
        .then((res) => {
          this.tableList = res.result.records;
        })
        .catch((msg) => {
          console.log(msg);
        });
    },
  },
};
</script>
<style lang="scss">
.i-c-teacher {
  position: relative;
  .i-text-remark-top {
    position: absolute;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/images/t_detail_icon_bg.png);
    top: -55px;
    right: 40px;
    width: 107px;
    height: 107px;
    z-index: 10;
  }
  .i-uesr-bg {
    position: absolute;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
  }
  .i-uesr-bg1 {
    background-image: url(/images/index/teacher_bg1.png);
    height: 64px;
    width: 64px;
    top: 140px;
    left: 80px;
  }
  .i-uesr-bg2 {
    background-image: url(/images/index/teacher_bg2.png);
    height: 41px;
    width: 48px;
    top: 50px;
    left: 85px;
  }
  .i-uesr-bg3 {
    background-image: url(/images/index/teacher_bg5.png);
    height: 308px;
    width: 280px;
    bottom: -60px;
    right: 50px;
  }
  .i-text-remark-bottom {
    position: absolute;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/images/index/teacher_bg3.png);
    // bottom: 0;
    top: 380px;
    right: 40px;
    width: 48px;
    height: 41px;
  }
  .i-img {
    position: absolute;
    right: -30px;
    bottom: -60px;
    width: 310px;
    height: 310px;
    overflow: hidden;
    margin: 0 auto;
    background: #333;
    z-index: 20;
    border-radius: 4px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-in-out;
    }
    &:hover img {
      opacity: 0.8;
      transform: scale(1.1);
      transition: all 0.4s ease-in-out;
    }
  }
}
// 教师轮播模块
.l-teacher {
  // overflow: hidden;
  padding-bottom: 10px;

  .el-carousel--horizontal {
    border-radius: 6px;
    box-shadow: 0px 12px 24px rgba(223, 227, 239, 0.7);
    backdrop-filter: blur(116px);
  }

  .el-carousel__container {
    height: 440px;
    // border: 1px solid #e2e6eb;
    background-color: #fff;
    border-radius: 4px;
  }
  .el-carousel__container {
    // el-carousel__arrow el-carousel__arrow--left
    .el-carousel__arrow {
      transition: 0s;
      border-radius: 4px;
      background-color: #fff;
      border: #777 1px solid;
      color: #777;
      top: 90% !important;
      &:hover {
        color: #1e74f4;
        border-color: #1e74f4;
      }
      i {
        font-size: 18px;
        font-weight: 800;
      }
      &.el-carousel__arrow--left {
        left: 92%;
      }
      &.el-carousel__arrow--right {
        right: 1%;
      }
    }
  }
  .el-carousel__indicators {
    // left: 16%;
    // display: none;
    margin-top: 10px;
    margin-bottom: 18px;
    .el-carousel__indicator {
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 9px;
        background-color: #555;
      }
    }
    .is-active {
      .el-carousel__button {
        background-color: #1e74f4;
      }
    }
  }
  .i-teacher {
    display: flex;
  }
  // 左侧图片区域
  .i-images {
    flex: 1;
    max-width: 430px;
    height: 280px;
    margin: 60px 60px 60px 0;
    position: relative;
    // padding: 60px 0;
    .i-uesr-bg {
      position: absolute;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 10;
    }
    .i-uesr-bg1 {
      background-image: url(/images/index/teacher_bg1.png);
      height: 64px;
      width: 64px;
      top: 140px;
      left: 80px;
    }
    .i-uesr-bg2 {
      background-image: url(/images/index/teacher_bg2.png);
      height: 41px;
      width: 48px;
      top: 0px;
      left: 85px;
    }
    .i-uesr-bg3 {
      background-image: url(/images/index/teacher_bg5.png);
      height: 308px;
      width: 280px;
      bottom: -60px;
      right: 50px;
    }
    .i-img {
      position: absolute;
      right: -30px;
      bottom: -60px;
      width: 310px;
      height: 310px;
      overflow: hidden;
      margin: 0 auto;
      background: #333;
      z-index: 20;
      border-radius: 4px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.2s ease-in-out;
      }
      &:hover img {
        opacity: 0.8;
        transform: scale(1.1);
        transition: all 0.4s ease-in-out;
      }
    }
  }
  // 右侧文字区域
  .i-text {
    flex: 1;
    position: relative;
    margin-top: 60px;
    .i-text-name {
      background-image: url(/images/index/teacher_bg6.png);
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 54px;
      width: 321px;
      cursor: pointer;
      h2 {
        font-size: 28px;
        line-height: 100px;
        width: 215%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
          color: #1e74f4;
        }
      }
    }
    .i-text-title {
      height: 40px;
      margin-top: 30px;
      p {
        font-size: 16px;
        line-height: 40px;
      }
    }
    .i-text-remark {
      height: 85px;
      margin: 20px 0;
      position: relative;

      .i-text-remark-html {
        overflow: hidden;
        height: 100%;
        padding: 0 30px 0 0;
      }
    }
    .i-text-remark-top {
      position: absolute;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(/images/t_detail_icon_bg.png);
      top: -55px;
      right: 40px;
      width: 107px;
      height: 107px;
      z-index: 10;
    }
    .i-text-remark-bottom {
      position: absolute;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(/images/index/teacher_bg3.png);
      bottom: 0;
      right: 40px;
      width: 48px;
      height: 41px;
    }
    .i-text-course {
      h3 {
        margin-bottom: 15px;
      }
      ul {
        height: 75px;
        overflow: hidden;
        li {
          float: left;
          margin-right: 20px;
          font-size: 14px;
          // color: #333;
          // border: #777 solid 1px;
          color: #1e74f4;
          border: #1e74f4 solid 1px;
          border-radius: 3px;
          padding: 5px 8px;
          margin-bottom: 10px;
          cursor: pointer;
          opacity: 0.9;
          transition: all 0.25s ease-in;
          a {
            color: #1e74f4;
          }
          &:hover {
            border-color: #1e74f4;
            color: #1e74f4;
            opacity: 1;
          }
        }
      }
    }
  }
}
.g-bd {
  background: #f5f6f9;
}
.index-teacher {
  margin-top: 40px;
  overflow: hidden;
  :nth-child(7n) {
    margin-right: 0px;
  }

  .item-teacher {
    float: left;
    width: 160px;
    height: 237px;
    margin-right: 30px;
    background: #fff;
    margin-bottom: 30px;
    border: 1px solid #e2e6eb;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    .item-img {
      width: 158px;
      height: 158px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 3px 3px 0 0;
        transition: all 0.2s ease-in-out;
      }

      &:hover img {
        // opacity: 0.8;
        // transform: scale(1.1);
        // transition: all 0.4s ease-in-out;
      }
    }

    h3 {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 14px;
      text-align: center;
      color: #333;
      padding: 20px 20px 3px;
    }

    .item-org {
      padding: 0 8px;

      p{
        font-size: 12px;
      }
    }

    p {
      height: 30px;
      line-height: 30px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
      font-size: 14px;
      opacity: 0.45;
    }
    .item-datas {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        color: #777;
        font-size: 12px;
      }
    }
    .item-details {
      color: #333;
      font-size: 14px;
      text-align: left;
      margin: 15px 20px;
      height: 38px;
      overflow: hidden;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    &:hover {
      cursor: pointer;
      border: 1px solid #1e74f4;
    }
  }
  .item-to-menu {
    width: 60px;
    height: 240px;
    border: 1px solid #e2e6eb;
    border-radius: 4px;
    color: #333;
    padding: 20px;
    align-items: center;
    display: flex;
    font-size: 14px;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      border: 1px solid #1e74f4;
      color: #1e74f4;
    }
  }
}
</style>


