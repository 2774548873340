<template>
  <div v-loading="loading">
    <div class="con-list f-clearfix" v-if="tableList&&tableList.length>0">
      <ul
        class="l-course f-clearfix"
        v-for="(item,index) in tableList"
        :key="index"
      >
        <div class="body-tool">
          <h2 class="l-title">
            <!-- <span class="l-title-icon"></span> -->
            {{item.name}}
            <!-- :class="['l-title-icon1','l-title-icon2','l-title-icon3','l-title-icon4'][index]" -->
          </h2>
          <span @click="toCourseList('default',item.id)">更多课程 <i class="el-more-icon el-icon-arrow-right"></i></span>
        </div>
        <!-- <div v-for="chlid in item.courseList" :key="chlid.id" class="child-list"> -->
          <li
            v-if="chlid.id!='flag'"
            :title="chlid.name"
            v-for="chlid in item.courseList" :key="chlid.id"
          >
            <a :href="detailUrl+chlid.id" target="_blank">
              <div class="item-img">
                <ImageWebp :img="chlid.coverImgUrl" :alt="chlid.name" class="bg-img" />
                <ImageWebp :img="chlid.coverImgUrl" :alt="chlid.name" class="img" />
                <!-- <img v-img.lazy="chlid.coverImgUrl" :alt="item.name" class="bg-img"/>
                <img v-img.lazy="chlid.coverImgUrl" :alt="item.name" class="img" /> -->
              </div>
              <h3>{{chlid.name}}</h3>
              <div class="info">
                <span class="item-school">{{chlid.organization}}</span>
              </div>
            </a>
          </li>
          <li class="item-no-data" v-else>
            <div class="item-img">
              <img v-img.lazy="chlid.coverImgUrl" :alt="item.name" class="bg-img"/>
              <img v-img.lazy="chlid.coverImgUrl" :alt="item.name" class="img" />
            </div>
            <h3>暂无课程</h3>
            <div class="info">
              <span class="item-school">{{chlid.organization}}</span>
            </div>
          </li>
        <!-- </div> -->
      </ul>
    </div>
  </div>
</template>
<script>
import ImageWebp from '@/components/Image.vue'
export default {
  components: {
    ImageWebp
  },
  data() {
    return {
      tableList: [],
      detailUrl: "/course/courseDetail?id=",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      loginUrl: this.$setting.loginUrl,
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.$http
        .get("/layout/home/course")
        .then((res) => {
          this.$nextTick(() => {
            this.loading = false;
            let item = res.result.recCategories;
            // this.$emit("getNewCourse", res.result.highRecCourses.length > 0);
            if (item && item.length > 0) {
              for (let j = 0; j < item.length; j++) {
                if (
                  item[j].courseList != null &&
                  item[j].courseList.length < 3
                ) {
                  let num = 3 - item[j].courseList.length;
                  for (let k = 0; k < num; k++) {
                    let nullArr = {
                      categoryId: "",
                      coverImgUrl: "/images/index/no_course.png",
                      id: "flag",
                      mode: "",
                      name: "",
                      organization: "",
                      position: "",
                    };
                    item[j].courseList.push(nullArr);
                  }
                } else if (item[j].courseList == null) {
                  let nullArr3 = [];
                  for (let l = 0; l < 3; l++) {
                    let nullArr2 = {
                      categoryId: "",
                      coverImgUrl: "/images/index/no_course.png",
                      id: "flag",
                      mode: "",
                      name: "",
                      organization: "",
                      position: "",
                    };
                    nullArr3.push(nullArr2);
                  }
                  item[j].courseList = nullArr3;
                }
              }
            }
            if (item && item.length < 7) {
              let num = 6 - item.length;
              for (let i = 0; i < num; i++) {
                let nullArr1 = {
                  id: "",
                  name: "未选择分类",
                  position: "",
                  courseList: [
                    {
                      categoryId: "",
                      coverImgUrl: "/images/index/no_course.png",
                      id: "flag",
                      mode: "",
                      name: "暂无课程",
                      organization: "",
                      position: "",
                    },
                    {
                      categoryId: "",
                      coverImgUrl: "/images/index/no_course.png",
                      id: "flag",
                      mode: "",
                      name: "暂无课程",
                      organization: "",
                      position: "",
                    },
                    {
                      categoryId: "",
                      coverImgUrl: "/images/index/no_course.png",
                      id: "flag",
                      mode: "",
                      name: "暂无课程",
                      organization: "",
                      position: "",
                    },
                  ],
                };
                item.push(nullArr1);
              }
            }
            this.tableList = item;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toCourseList(val, id) {
      window.open(`/course?pathFalg=${val}&categoryId=${id}&isOnline=&key=`);
    },
    // getList() {
    //   this.$http
    //     .post("api/web/course/categoryMap")
    //     .then(res => {
    //       if (res.data != "") {
    //         let num = 0;
    //         Object.keys(res.data).forEach(key => {
    //           let par = {
    //             name: key,
    //             list: res.data[key]
    //           };
    //           if (res.data[key] == "") {
    //             num++;
    //           }
    //           this.tableList.push(par);
    //         });
    //         this.$emit("getHotCourse", num != 4);
    //       } else {
    //         this.$emit("getHotCourse", false);
    //       }
    //     })
    //     .catch(msg => {
    //     });
    // }
  },
};
</script>
<style lang="scss">
.con-list {
  .l-course:nth-child(2n) {
    margin-left: 40px;
  }
  .l-course {
    display: inline-block;
    width: 580px;
    :last-child {
      margin-right: 0px;
    }
    .body-tool {
      padding: 0 10px 15px 0;
    }
    .l-title {
      font-size: 20px;
      display: inline-block;

      span {
        margin-left: 15px;
        font-size: 14px;
        color: #777;
        cursor: pointer;
        &:hover {
          color: #1e74f4;
          .el-more-icon {
            color: #1e74f4;
          }
        }
      }
    }

    .child-list, li {
      // box-shadow: 0px 2px 14px rgba(2, 41, 110, 0.1);
      // border-radius: 2px;
      box-shadow: 0px 12px 24px rgba(223, 227, 239, 0.7);
      backdrop-filter: blur(116px);
      border-radius: 6px;
      overflow: hidden;
      float: left;
      width: 178px;
      margin-right: 20px;
      margin-bottom: 30px;
      height: 206px;
      background: #fff;
      a:hover {
        color: #1e74f4;
      }
      .item-img {
        width: 178px;
        height: 103px;
        border-radius: 3px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        overflow: hidden;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
          transition: all 0.2s ease-in-out;
        }
        &:hover .img {
          opacity: 0.8;
          transform: scale(1.1);
          transition: all 0.4s ease-in-out;
        }
        .img {
          object-fit: contain;
          position: absolute;
          left: 0;
          top: 0;
        }
        .bg-img {
          width: 180px;
          height: 100px;
          filter: blur(10px);
        }
      }
      h3 {
        font-size: 14px;
        overflow: hidden;
        height: 55px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        padding: 15px 20px 0 15px;
      }
      .info {
        padding: 12px 15px 0;

        span {
          color: #777;
          font-size: 12px;
        }
        .item-school {
          width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #253044;
          opacity: 0.4;
        }

        .item-edu {
          float: right;
          text-align: right;
        }
      }
      & :hover {
        cursor: pointer;
      }
    }
    .item-no-data {
      .item-img {
        width: 120px;
        height: 78px;
        margin: 25px auto 10px;
      }
      h3 {
        text-align: center;
        color: #ddd;
        font-weight: bold;
      }
    }
  }
}
</style>


