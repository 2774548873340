<template>
  <div>
    <div class="con-list f-clearfix">
      <!-- <div class="org_con_msg"></div> -->
      <ul class="index-school f-clearfix">
        <li
          v-for="(item) in tableList"
          :key="item.id"
          :title="item.id"
          @click="toDetail(item)"
        >
          <!-- <a
            :href="item.link!=''?item.link:'javascript:void(0);'"
            :target="item.link!=''?'_blank':'_self'"
          > -->
            <div class="item-img">
              <ImageWebp :img="getUrl(item.logo)" class="bg-img" />
              <!-- <img :src="getUrl(item.logo)" alt /> -->
            </div>
          <!-- </a> -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ImageWebp from '@/components/Image.vue'
export default {
  components: {
    ImageWebp
  },
  data() {
    return {
      tableList: [],
      imgUrl: this.$setting.fdsDomain,
      pageList: {
        pageNo: 1,
        pageSize: 4,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getUrl(url) {
      if (!url) {
        return url;
      }
      if (url.startsWith("http")) {
        return url;
      } else {
        return this.imgUrl + url;
      }
    },
    toDetail(val) {
      // window.open(`/school/schoolDetail?id=${val.id}`);
      // "/teacher/teacherDetail?id=" + val.id + "&openUid=" + val.openUid;
    },
    getList() {
      this.$http
        .get("/organization/home/page")
        .then((res) => {
          const { result: { resultList }} = res
          this.tableList = resultList;
          this.$emit("getOrg", resultList.length > 0);
        })
        .catch((msg) => {
          console.log(msg);
        });
    },
  },
};
</script>
<style lang="scss">
.org_con_msg {
  width: 1200px;
  height: 290px;
  background-image: url(/images/index/orgLogo.png);
  background-size: 100%;
}
.index-school {
  // overflow: hidden;
  padding-top: 5px;
  max-height: 285px;
  :nth-child(5n) {
    margin-right: 0px;
  }
  li {
    float: left;
    width: 220px;
    height: 110px;
    margin-right: 25px;
    margin-bottom: 25px;
    .item-img {
      transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
      border: 1px solid #e6e9f0;
      overflow: hidden;
      border-radius: 4px;
      background: #fff;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
      &:hover {
        // box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 12px 24px rgba(223, 227, 239, 0.7);
        border: 0;
        img {
          transform: scale(1.05);
        }
      }
      img {
        transition: transform 0.3s, -webkit-transform 0.3s;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>


