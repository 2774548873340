<template>
  <div>
    <div class="con-list f-clearfix">
      <ul class="con-course index-course f-clearfix" v-loading="loading">
        <div v-for="(item,index) in tableList" :key="index">
          <li
            v-if="item.id!='flag'"
            :title="item.name"
          >
            <a :href="detailUrl+item.id" target="_blank">
              <div class="item-img">
                <ImageWebp :img="item.coverImgUrl" :alt="item.name" class="bg-img" />
                <ImageWebp :img="item.coverImgUrl" :alt="item.name" class="img" />
                <!-- <img v-img.lazy="item.coverImgUrl" :alt="item.name" class="bg-img"/>
                <img v-img.lazy="item.coverImgUrl" :alt="item.name" class="img" /> -->
              </div>
              <div :class="fGetStatus(item).statusStyle">
                <span class="item-online-text">
                  {{fGetStatus(item).statusText}}
                  </span>
              </div>
              <h3>{{item.name}}</h3>
              <div>
                <span class="item-school">{{item.organization}}</span>
              </div>
            </a>
          </li>
          <li class="item-no-data" v-else>
            <div class="item-img">
              <img v-img.lazy="item.coverImgUrl" :alt="item.name" class="bg-img"/>
              <img v-img.lazy="item.coverImgUrl" :alt="item.name" class="img" />
            </div>
            <h3>暂无课程</h3>
            <div>
              <span class="item-school">{{item.organization}}</span>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import ImageWebp from '@/components/Image.vue'
export default {
  components: {
    ImageWebp
  },
  data() {
    return {
      statusStyle: '',
      tableList: [],
      pageList: {
        pageNo: 1,
        pageSize: 24,
        total: 0,
      },
      detailUrl: "/course/courseDetail?id=",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      loginUrl: this.$setting.loginUrl,
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    fGetStatus(item) {
      let itemObj = {
        statusStyle: '',
        statusText: ''
      }
      const { hasLive, liveStartTime, liveStatus, mode } = item
      if ( mode === 'OFFLINE' ) {
        itemObj = {
          statusStyle: 'item-online-box f-offline',
          statusText: '线下授课'
        }
      } else {
        if (hasLive) { // 直播
          if (liveStatus === 'NOT_START') { // 未开始
            itemObj = {
              statusStyle: 'item-online-box f-unstart',
              statusText: `${liveStartTime} 开始直播`
            }
          } else if (!liveStatus) {
              itemObj = {
              statusStyle: 'item-online-box f-active',
              statusText: `${liveStartTime} 线上授课`
            }
          } else {
            itemObj = {
              statusStyle: 'item-online-box f-live',
              statusText: '正在直播'
            }
          }
        } else {
          itemObj = {
            statusStyle: 'item-online-box f-active',
            statusText: '线上授课'
          }
        }
      }
      return itemObj
    },
    getList() {
      this.loading = true;
      this.$http
        .get("/layout/home/course")
        .then((res) => {
          this.$nextTick(() => {
            this.loading = false;
            let item = res.result.highRecCourses;
            if (item && item.length > 0) {
              for (let j = 0; j < item.length; j++) {
                if (item && item.length < 4) {
                  let num = 4 - item.length;
                  for (let k = 0; k < num; k++) {
                    let nullArr = {
                      categoryId: "",
                      coverImgUrl: "/images/index/no_course.png",
                      id: "flag",
                      mode: "",
                      name: "",
                      organization: "",
                      position: "",
                    };
                    item.push(nullArr);
                  }
                }
              }
            }
            this.tableList = item;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">

.index-course {
  padding: 6px 0;
  max-height: 240px;
  :nth-child(4n) {
    margin-right: 0px;
  }
  li {
    margin-bottom: 15px;
  }
  .item-no-data {
    .item-img {
      width: 200px;
      height: 126px;
      margin: 30px auto 10px;
      overflow: hidden;
      position: relative;
      .img {
        object-fit: contain;
        position: absolute;
        left: 0;
        top: 0;
      }
      .bg-img {
        width: 276px;
        height: 160px;
        filter: blur(20px);
      }
    }
    h3 {
      text-align: center;
      color: #ddd;
      font-weight: bold;
    }
  }
}
.con-list {
  .con-course {
    li {
      // box-shadow: 0px 2px 14px rgba(2, 41, 110, 0.1);
      box-shadow: 0px 12px 24px rgba(223, 227, 239, 0.7);
      backdrop-filter: blur(116px);
      border-radius: 6px;
      h3 {
        font-size: 14px;
        padding: 15px 18px 2px;
      }
      .item-school {
        font-size: 12px;
        // color: #253044;
        color: #6F6F6F;
        padding: 0 18px;
        opacity: 0.8;
      }
      .item-img {
        overflow: hidden;
        position: relative;
        border-radius: 6px 6px 0 0;
        .img {
          object-fit: contain;
          position: absolute;
          left: 0;
          top: 0;
        }
        .bg-img {
          width: 276px;
          height: 160px;
          filter: blur(20px);
        }
      }
      
    }
  }
}

</style>


